export const firebaseConfig = {
    apiKey: "AIzaSyBJFLH3AhhX2JkHwItN8P5iXgQPwvVMbx0",
    authDomain: "drumpig-40c89.firebaseapp.com",
    databaseURL: "https://drumpig-40c89.firebaseio.com",
    projectId: "drumpig-40c89",
    storageBucket: "drumpig-40c89.appspot.com",
    messagingSenderId: "826465545783",
    appId: "1:826465545783:web:4399fabc8b5777684dd9b2",
    measurementId: "G-9WD1J3DBJC"
};
