import * as React from "react"
// import * as firebase from 'firebase/app';
// import "firebase/auth";

import {firebaseConfig} from "../confiig/firebase_config";
import {useEffect, useState} from "react";
import {StyledFirebaseAuth} from "react-firebaseui";
// import {StyledFirebaseAuth} from "react-firebaseui";



// const app = firebase.initializeApp(firebaseConfig);

// Configure FirebaseUI.



let firebaseInstance
export const getFirebase = firebase => {
    if (firebaseInstance) {
        return firebaseInstance
    }

    firebase.initializeApp(firebaseConfig)
    firebaseInstance = firebase;
    return firebase
}



export function FirebaseLogin() {

    const [firebase, setFirebase] = useState();
    useEffect(() => {
        const lazyApp = import('firebase/app')
        const auth = import('firebase/auth')
        Promise.all([lazyApp]).then(([firebase]) => {
            firebase.initializeApp(firebaseConfig);

            firebase.auth().onAuthStateChanged(function(user) {
                if (user) {
                    console.log("user", user)
                } else {
                    console.log("No user")
                    // No user is signed in.
                }
            });

            setFirebase(firebase);

            // const database = getFirebase(firebase).database()
            // do something with `database` here,
            // or store it as an instance variable or in state
            // to do stuff with it later
        });
    }, []);

    if(firebase){
        const uiConfig = {
            // Popup signin flow rather than redirect flow.
            signInFlow: 'redirect',
            // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
            signInSuccessUrl: '/play',
            // We will display Google and Facebook as auth providers.
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                firebase.auth.EmailAuthProvider.PROVIDER_ID,
            ]
        };
        return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
    }else{
        return <div>LOAD...</div>
    }




    // return <div></div>
}